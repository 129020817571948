<template>
  <div class="tickets">
    <div class="ticket-header">
      <div>
        <ul class="tabs">
          <li
            :class="[
              activeTab === 'open'
                ? 'openTicket'
                : '',
            ]"
            @click="select('open')"
          >
            {{ $t("open") }}
          </li>
          <li
            :class="[
              activeTab === 'closed'
                ? 'closeTicket'
                : '',
            ]"
            @click="select('closed')"
          >
            {{ $t("closed") }}
          </li>
        </ul>
      </div>
      <div>
        <!--
          :class="[providerUniqueId === 'z01x1wPo' ? 'primary-nammaflix-button' : 'primary-net5-button']"

        -->
        <button
          class="button-primary"
          @click="toggleCreateTicket(true)"
        >
          {{ $t("create ticket") }}
        </button>
      </div>
    </div>
    <div class="ticket-status">
      <!-- <Loading v-if="showLoader"></Loading> -->
      <!-- <ErrorAlert
        class="error-alert"
        v-if="showError"
        :message="message"
      ></ErrorAlert> -->

      <div class="error-message" v-if="showError">
        {{ $t(message) }}
      </div>

      <div>
        <div v-for="(ticket, index) in tickets" :key="index">
          <div class="status-card" @click="toggleTicketHistory(ticket, true)">
            <div class="left-side">
              <p class="card-title">{{ $t(ticket.title) }}</p>
              <p class="description">
                {{ $t("Updated on") }}:
                <span>{{ displayDateFormat(ticket.created) }}</span>
              </p>
              <p class="description admin" v-if="ticket.updatedby">
                <span>{{ $t("category") }}</span
                >:
                <span>"{{$t(getCategoryName(ticket.category))}}"</span>
              </p>
            </div>
            <div>
              <p class="attachments">
                <!-- {{ ticket.attachmentcount }} {{ $t("Documents") }} -->
                <!-- <img src="@/assets/icons/Download.svg" /> -->
              </p>
              <p class="attachments details">
                {{ $t("View Ticket") }}
                <img src="@/assets/icons/Drop_down.svg" />
              </p>
            </div>
          </div>
        </div>
        <div v-if="isMoreDataLoading">
          <Loading></Loading>
        </div>
      </div>
    </div>
    <transition>
      <div>
        <createTicket
          v-if="enableTicket"
          :closePopup="() => toggleCreateTicket(false)"
          @refreshList="refreshTicketList"
        />
        <ticketHistory
          v-if="enableTicketHistory"
          :ticket="ticketInfo"
          :selectedTab="currentlySelectedTab"
          :closePopup="() => toggleTicketHistory(null, false)"
          @refreshList="refreshTicketList"
        />
      </div>
    </transition>
  </div>
</template>

<script>
import { eventBus } from "@/eventBus";
import { mapGetters } from "vuex";
import moment from "moment";
import Vue from "vue";
Vue.prototype.moment = moment;
import Utility from "@/mixins/Utility.js";
import { _providerId } from "@/provider-config.js";

export default {
  mixins: [Utility],

  components: {
    Loading: () => import(/* webpackChunkName: "Loading" */ "@/components/Templates/Loading.vue"),
    ErrorAlert: () => import(/* webpackChunkName: "ErrorAlert" */ "@/components/Templates/ErrorAlert.vue"),
    createTicket: () =>
      import(/* webpackChunkName: "createTicket" */ "@/components/Popups/ProfilePopups/createTicket.vue"),
    ticketHistory: () =>
      import(/* webpackChunkName: "ticketHistory" */ "@/components/Popups/ProfilePopups/ticketHistory.vue"),
  },

  data() {
    return {
      tickets: [],
      showLoader: true,
      showError: false,
      activeTab: null,
      enableTicket: false,
      enableTicketHistory: false,
      ticketType: null,
      ticketInfo: null,
      // subscriberDetails: null,
      providerUniqueId: _providerId,
      totalTicketsCount: null,
      ticketsPageNumber: 1,
      isMoreDataLoading: false,
      currentlySelectedTab: null,
      localDisplayLang: null,
      errorOccured: false,
      message: ''
    };
  },

  watch: {
    ticketType(val) {
      if (val) {
        this.listTickets();
      }
    },
  },

  computed: {
    ...mapGetters(["appConfig"]),
  },

  created() {
    this.currentlySelectedTab = "open";
    this.localDisplayLang = localStorage.getItem("setDisplayLanguageCode");
    this.select(this.currentlySelectedTab);
  },

  mounted() {
    eventBus.$on("ticket-response", (payload) => {
      if (this.tickets && this.tickets.length > 0) {
        this.tickets = [...this.tickets, ...payload.data];
      } else {
        this.tickets = [...payload.data];
      }
      this.tickets = this.tickets.filter((ticket) => {
        if (this.currentlySelectedTab == 'open') {
          if (ticket.requeststatus !== "CLOSED") {
            return ticket
          } 
        } else {
          if (ticket.requeststatus == "CLOSED") {
            return ticket
          } 
        }
        
      })

      this.isMoreDataLoading = false;

      this.totalTicketsCount = payload.totalcount;

      this.showLoader = false;
      this.showError = false;
    });

    eventBus.$on("show-error-alert", (payload) => {
  this.errorOccured = true;
  this.showLoader = false;
  this.isMoreDataLoading = false;

  let errorMessage = payload.reason;
  if (payload.errorcode === 9712) {
    if (this.currentlySelectedTab === "open") {
      errorMessage = "No open tickets available";
    } else if (this.currentlySelectedTab === "closed") {
      errorMessage = "No closed tickets available";
    }
  }

  this.showError = this.tickets.length === 0 || !this.errorOccured;
  this.message = "No tickets available";
});

    window.addEventListener("scroll", () => {
      var scrollHeight, totalHeight;
      scrollHeight = document.body.scrollHeight;
      totalHeight = window.scrollY + window.innerHeight;

      if (totalHeight >= scrollHeight) {
        // this.isMoreDataLoading = true;
        console.log("REACHED THE END OF THE PAGE");

        if (!this.errorOccured && !this.isMoreDataLoading) {
          this.isMoreDataLoading;
          this.ticketsPageNumber++;
          this.listTickets();
        }
      }
    });
  },

  methods: {
    listTickets() {
      this.isMoreDataLoading = true;
      let payload = {
        requeststatus: this.ticketType === "open" ? "OPEN" : "CLOSED",
        page: this.ticketsPageNumber,
        pagesize: this.appConfig.defaultpageSize,
      };

      if (this.localDisplayLang !== "eng") {
        payload.displaylanguage = this.localDisplayLang;
      }

      eventBus.$emit("list-tickets", payload);
    },

    select(value) {
      this.ticketsPageNumber = 1;
      this.currentlySelectedTab = value;
      this.activeTab = value;
      this.ticketType = value;
      this.tickets = [];
      this.showError = false;
      this.showLoader = true;
    },

    toggleCreateTicket(state) {
      this.enableTicket = state;
    },

    toggleTicketHistory(ticket, state) {
      this.enableTicketHistory = state;

      console.log("Ticket -->", ticket);
      this.ticketInfo = ticket;
    },

    refreshTicketList() {
      this.showError = false;
      this.isMoreDataLoading = true;
      this.showLoader = true;
      this.tickets = [];
      let payload = {
        requeststatus: this.currentlySelectedTab.toUpperCase(),
        page: this.ticketsPageNumber,
        pagesize: this.appConfig.defaultpageSize,
      };

      if (this.localDisplayLang !== "eng") {
        payload.displaylanguage = this.localDisplayLang;
      }

      console.log("THE PAYLOAD Again --> ", payload);

      eventBus.$emit("list-tickets", payload);
    },
  },

  beforeDestroy() {
    eventBus.$off("ticket-response");
    eventBus.$off("show-error-alert");
  },
};
</script>

<style lang="scss">
@import "@/sass/_variables.scss";
@import "@/sass/_components.scss";

.openTicket {
  border-bottom: 1.5px solid $clr-tickets-open-under-txt-cr !important;
  color: $clr-tickets-open-txt !important;
 }
 .closeTicket {
    border-bottom: 1.5px solid #ff9600 !important;
    color: rgb(239, 239, 244) !important;
 }
.tickets {
  padding: 30px 20px;

  .error-message {
    text-align: center;
    color:$clr-tickets-err-msg-txt;
    font-size: 1em;
  }

  .ticket-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #2a2a2a;
    .tabs {
      display: flex;
      li {
        color: rgba(239, 239, 244, 0.5);
        font-family: $font-regular;
        font-size: 14px;
        font-weight: 500;
        letter-spacing: 0;
        line-height: 20px;
        padding: 7px 10px;
        cursor: pointer;
      }
    }
    button {
      margin-bottom: 0.5rem;
      padding: 5px 25px;
    }
  }

  .left-side {
    width: 65%;
  }

  .ticket-status {
    padding: 20px 0px;
    .status-card {
      padding: 20px;
      margin-bottom: 10px;
      background-color: #212121;
      border-radius: 5px;
      display: flex;
      justify-content: space-between;
      cursor: pointer;

      .card-title {
        color: #efeff4;
        font-family: $font-regular;
        font-size: 1.2rem;
        font-weight: bold;
        // letter-spacing: -0.21px;
        word-break: break-all;
        line-height: 20px;
        margin-bottom: 0.3rem;
      }
      .description {
        color: #868688;
        font-family: $font-regular;
        font-size: 0.8rem;
        letter-spacing: 0;
        line-height: 16px;
      }
      .admin {
        margin-top: 10px;
      }
      .attachments {
        color: #868688;
        font-family: $font-regular;
        font-size: 14px;
        letter-spacing: 0;
        line-height: 16px;
      }
      .details {
        padding-top: 5px;
        font-size: 12px;
        text-align: end;
      }
      &:hover {
        transition: all 0.3s ease-in;
        transform: scale(1.02);
        background-color: #282828;
      }
    }
  }
}

@media only screen and (max-width: 576px) {
  .tickets {
    padding: 10px 10px;

    .error-message {
      font-size: 0.8em;
    }

    .ticket-header {
      .tabs {
        li {
          font-size: 12px;
        }
      }
      button {
        margin-bottom: 0.5rem;
        padding: 5px 25px;
      }
    }
    .ticket-status {
      padding: 20px 0px;
      .status-card {
        padding: 18px 10px;
        margin: 10px 0;
        .card-title {
          font-size: 1rem;
          max-width: 175px;
        }
        .description {
          font-size: 0.7rem;
          letter-spacing: 0;
        }
        .admin {
          margin: 5px 0;
        }
        .attachments {
          font-size: 12px;
        }
        .details {
          padding: 8px 0;
          font-size: 12px;
        }
      }
    }
  }
}
</style>
